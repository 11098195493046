
/* width */
::-webkit-scrollbar {
    width: 2px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    /* background: #cdcdcd; */
    background: transparent;
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ffffff0f;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 20px;
}

button:focus {
    outline: none !important;
}
input:focus {
    outline: none !important;
}

.container {
    // margin-bottom: 100px;
    height: calc(100vh - 145.5px);
    overflow: auto;
    max-width: 100%;

    .styled-form {
        width: 380px;
        margin: 30px auto;
            
        .form-group {
            margin-bottom: 10px;
        }

        .medium-input {
            .form-control {
                width: 190px;
            }
        }

        .small-input {
            .form-control {
                width: 49px;
            }
        }

        .single-line-input {
            .input-container {
                display: flex;
                justify-content: center;
                align-items: center;
                input {
                    width: 73px;
                }
                label {
                    padding-right: 15px;
                    padding-left: 0px;
                }
            }
            .error-label {
                text-align: right;
            }
        }

        .width-112 {
            input {
                width: 112px !important;
            }
        }

        input, .selected-labels {
            color: #ccdbe7;
            border: 1.5px solid #6793B7;
            background: #1E2C35 !important;
            border-radius: 20px;
            height: unset;
            width: 380px;
        }

        input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #6793b7;
            opacity: 1; /* Firefox */
        }

        .form-label {
            font-size: 14px;
            color: #6793B7;
            margin-bottom: 0px;
            padding-left: 14px;
        }

        .home-input input {
            margin: auto;
        }

        @media (max-width: 570px) {
            .home-input input {
                width: 318px;
            }
        }

        @media (max-width: 570px) {
            input, .selected-labels, .dropdown select {
                width: 100%;
            }
        }

        .box {
            margin: 15px -15px 8px -15px;
            border-radius: 7px;
            background-color: #2f4455;
            padding: 7px 11.6px 13.5px 12.7px;

            &.center-box {
                padding: 18px;
                margin: auto;
                width: 230px;
                margin-bottom: 22px;
            }
        }

        @media (max-width: 390px) {
            .box {
                margin: 15px 0px 8px 0px;
            }
        }

        .color-box {
            background-color: #009d86;
            margin-bottom: 35px;

            & .form-label {
                color: #fff;
            }

            & input {
                background: #fff !important;
                color: #1e2c35 !important;
            }
        }

        .input-holder.checkbox {
            margin-top: 20px;

            .input-container {
                display: flex;
                justify-content: center;

                label span {
                    color: #c8e0ed;
                    position: relative;
                    top: -1px;
                }

                .light-label span {
                    font-size: 13px;
                    color: #6793b7 !important;
                    position: relative;
                    top: -1px;
                }
            }
        }

        .divider {
            height: 1px;
            margin: 32px 0;
            background-color: #3d566a;
            display: block;
            text-align: center;

            .title {
                font-size: 15px;
                line-height: 1.27;
                color: #6793b7;    
                position: relative;
                top: -9px;
                background: #273947;
                width: 100px;
                margin: auto;
                margin-bottom: 32px;
            }
        }

        .title {
            font-size: 16.7px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.84px;
            color: #bdc7d0;
            margin-bottom: 8px;
            display: block;
        }
    }


    @media (max-width: 350px) {
        .home-form input, .selected-labels, .dropdown select {
            width: 260px !important;
        }
    }

    @media (max-width: 570px) {
        .styled-form {
            width: 330px;
        }
    }

    @media (max-width: 350px) {
        .styled-form {
            width: 290px;
        }
    }
}

.summary-container {
    height: calc(100vh - 85.5px);
}

.black-box {
    background-color: #20313e;
    max-width: 100%;

    .styled-form {
        width: 380px;
        margin: 0 auto;
    }

    @media (max-width: 570px) {
        .styled-form {
            width: 330px;
        }
    }

    @media (max-width: 395px) {
        .styled-form {
            width: 345px;       }
    }

    .styled-column {
        padding: 0 20px;
        border-right: 1px solid #3d566a;
        margin: 62px 0;
        margin-bottom: 62px !important;
        
        &:last-child {
            border: none;
        }

        div {
            font-size: 11px;
            color: #c8e0ed;
            display: flex;

            b {
                width: 50px;
            }
        }
    }
}

.match-found-result {
    // margin-bottom: 60.5px;
    position: fixed;
    bottom: 60.5px;
    width: 100%;
}

.match-found-container {
    width: 100%;

    .title {
        height: 35.9px;
        padding: 7px 0px;
        background-color: var(--teal);
        font-size: 13px;
        font-weight: bold;
        line-height: 1.08;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .details {
        height: 83.1px;
        padding: 15px 10px;
        background-color: #ffffff;
    }

    .name-details {
        margin: 0 60px 6.6px 40px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.13;
        color: #3a5c77;
        text-align: center;
    }

    .other-details {
        margin: 0 0 3.3px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.08;
        color: #6791b5;
        display: flex;
        justify-content: space-between;
        max-width: 320px;
        margin: auto;
    }

}

.footer-container {
    height: 60.5px;
    margin: 0.2px 0 0;
    background-color: #3a5c77;
    border-top: 3px solid #b2d8f7;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary {
    border: none;
    min-width: 120px;
    height: 35px;
    margin: 0 22px;
    padding: 1px;
    border-radius: 17.5px;
    background-color: #6793b7;
    color: white;
    font-size: 14px;
    line-height: 6px;
}

.staff_identifier {
    margin-right: 15px;
    color: #5485ad;
}

.radio-box {
    display: flex;
    align-items: center;   
    padding: 16px !important; 
    margin-top: 30px !important;
    margin-bottom: 0px !important;

    &.center {
        margin-top: 0 !important;
        justify-content: center;
        padding: 10px 0px 0px 0px !important;
    }
}

.radio-filed {
    display: flex;
    align-items: center;    
    margin-right: 45px; 
    margin-bottom: 0 !important;

    input {
        cursor: pointer;
    }

    label {
        padding-left: 5px !important;
        font-size: 16px !important;
        cursor: pointer;
    }
}

.radio-filed:last-child {    
    margin-right: 0;
}

.employed-radio-filed {
    margin-right: 25px; 
}

.hide {
    display: none;
    opacity: 0;
}

.show {
    display: block;
    opacity: 1;
}

.apply-application {
    width: 335px;
    margin: 30vh auto;

    .button {
        cursor: pointer;
        display: flex;
        border-radius: 5px;
        background-color: #009d86;
        height: 59px;
        margin-bottom: 28px;
        padding: 0;
        border: 0;

        .icon {    
            width: 58px;
            height: 59px;
            padding: 12.5px 18.2px 11.9px 17.8px;
            background-color: #ffffff;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }
        .label {
            height: 34px;
            margin: 12.5px 30px 12.5px 30px;
            font-size: 14px;
            line-height: 1.2;
            text-align: center;
            color: #ffffff;
        }
    }
}

@media (max-width: 350px) {
    .apply-application {
        width: 315px;
        padding: 0px 10px;
    }
}

.sub-header {
    display: flex;
    button {
        width: 100%;
        height: 37.2px;
        background-color: #2f4455;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin-right: 2.3px;

        &:last-child {
            margin-right: 0px;
        }

        &.active {
            background-color: #009d86 !important;
        }

        img {
            width: 25px;
            height: 20.6px;
            // margin-right: 10px;
        }

        span {
            font-size: 11px;
            font-weight: bold;
            color: #ffffff;
        }

        .arrow-down {
            position: absolute;
            margin-bottom: -45px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 10px solid #309d86;
        } 
    }
}


.styled-row {
    display: flex;
    justify-content: space-between;

    .styled-column {
        .selected-labels, .dropdown select {
            width: 174px !important;
        }

        input {
            width: 115px;
        }
    }
}


.summary-row {

    .col {
        // margin-top: 15px;
        padding: 10px; 
        // margin-bottom: 62px !important;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        
        &:first-child {
            padding-left: 0px;
        }
        
        &:last-child {
            border: none;
            padding-right: 0px;
        }
    }

    span {
        font-size: 11px;
        color: #c8e0ed;
        display: flex;

        b {
            width: 50px;
            display: inline-table;
        }
    }

    span.status {
        text-align: center;
        display: block;
        font-weight: bold;
        margin-bottom: 6px;
    }

    span.status-icon {
        width: 100px;
        margin: auto;
        padding: 4px;
        background-color: #2d4a5f;
        border-radius: 12px;
        text-align: center;
        display: block;
        // margin-top: 14px;

        img {
            float: left;
            background: #6793b7;
            padding: 6px 6px;
            border-radius: 12px;
            margin: -3px;
            width: 22px;
        }
    }

    span.status-icon-2 {
        img {
            float: left;
            background: #009D86;
            padding: 6px 6px;
            border-radius: 12px;
            margin: -3px;
            width: 22px;
        }
    }

    @media (max-width: 570px) {
        .col {
            // margin-top: 15px;
            // margin-bottom: 15px !important;
        }
    }
}

.button-col {
    display: flex;
    align-items: center;
}

.welcome-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66vh;
    text-align: center;

    p {
        font-size: 15.9px;
        line-height: 1.32;
        color: #c8e0ed;
    }

    b {
        // display: block;
        font-size: 14.5px;
        font-weight: bold;
        line-height: 1.2;
        color: #c8e0ed;
    }
}

.has-amount {
    text-align: center;
    padding-left: 30px;
}

.has-amount-sign {
    position: relative;
    margin: 7px 0px 7px 16px;
    top: -31px;
}

.pointer-none {
    pointer-events: none !important;
}

.modal-footer {
    justify-content: center;
    & button {
        margin: 0 8px;
    }
}

#have_co_applicant {
    width: 25px !important;
}

table.summary-row {
    margin: auto;

    td {
        border-right: 1px solid #3d566a;  
        padding-right: 10px;

        button {
            margin: 0;
            min-width: 100px;
        }
    }
    td:last-child {
        border-right: none;
        padding: 3px 20px;    
        padding-right: 0;
    }
} 

.table-div {
    padding: 62px 0;    
    padding-bottom: 5px;
    max-width: 470px;
    margin: auto;
    width: 100%;
    overflow: auto;  
    
    &.status {
        padding: 20px 0;   
    }
}

.ssn-span {
    color: white;
    margin-top: -22px;
    margin-left: 14px;
    display: block;
    cursor: text;
    letter-spacing: 1px;
    height: 8px;
    line-height: 1px;
    width: 100px;
    margin-bottom: 14px;
}

.ssn-div {
    min-height: 63px;
}

.ssn-eye {
    position: absolute;
    width: 30px;
    right: 13px;
    top: 32px;
    cursor: pointer;
}

@media (max-width: 570px) {
    .table-div {
        padding: 22px 0;
    }
}



