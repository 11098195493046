@media (max-width: 570px) {
    .sales .home {
        width: auto !important;
        padding: 0px 50px;
        .button {
            height: 80px !important;
            margin-top: 40px !important;
            .icon {
                width: 80px !important;
                height: 80px !important;

                .icon-new-customer {
                    width: 30px !important;
                    height: 30px !important;
                    margin-left: 25px !important;
                    margin-top: 25px !important;
                }
                
                .icon-reorder-customer {
                    width: 30px !important;
                    height: 30px !important;
                    margin-left: 25px !important;
                    margin-top: 25px !important;
                }
                
                .icon-prequalify {
                    width: 30px !important;
                    height: 22px !important;
                    margin-left: 25px !important;
                    margin-top: 29px !important;
                }
            }
            .label {
                font-size: 20px !important;
            }
        }
    }
}

@media (min-width: 571px) {
    .sales .home {
        // margin: auto;
        // position: absolute;
        // left: 0;
        // top: 0;
        // right: 0;
        // bottom: 0;
        margin-left: calc(50% - 278px);
        margin-top: 50px;
        width: 555px;
        height: fit-content;
        border-radius: 3px;
        background-color: rgba(58, 91, 119, 0.32);
        padding: 51px 52px 52px 56px;
    }
}






























.main-box {
    max-width: 380px;
    margin: 0px auto;

    .title {
        margin: 0 45.4px 41px 45.6px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        color: #ffffff;
        // margin-top: 70px;
        margin-top: 32px;
        margin-bottom: 41px;
        text-align: center;
    }

    .sub-title {
        margin: 41px 10px 45px 7px;
        font-size: 13px;
        text-align: center;
        color: #6793b7;
    }
}