.search-header {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3A5C77;

    form {
        
        .form-group {
            margin-bottom: 0;
        }

        input {
            color: #5D829B !important;
            border: 2px solid #6793B7;
            background: #1E2C35 !important;
            border-radius: 20px;
            height: unset;
            min-width: 380px;
        }

        @media (max-width: 570px) {
            input {
                min-width: 315px;
            }
        }
    }
}

.list {
    height: calc(100vh - 110px);
    overflow: auto;

    .single-row {
        // padding: 15px;
        padding-bottom: 7px;
        margin-top: 5px;
        margin-bottom: 0;
        cursor: pointer;

        .dealer-row {
            width: 380px;
            display: flex;
            padding: 12px 0;
            border-bottom: 1px solid #3A5C77;
            margin: auto;
        }

        @media (max-width: 570px) {
            .dealer-row {
                padding: 12px;
            }
        }

        .dealer-name {
            background: #3A5B77;
            border-radius: 20px;
            padding: 2px 10px;
            color: #B2D8F7;
            font-size: 14px;
        }

        .dealer-action {
            text-align: right;

            .arrow-icon-active {
                background: #309e87;
                padding: 6px;
                width: 25px;
                height: 25px;
                border-radius: 20px;
                transform: rotate(90deg);
                transition-duration: .2s;
                fill: white;
            }

            .arrow-icon {
                height: 12px;
                float: right;
                padding: 6px;
                width: 25px;
                height: 25px;
                border-radius: 20px;
                cursor: pointer;
                fill: #B2D8F7;
            }
        }
    }
    
    .single-row:hover .arrow-icon {
        background: #309e87;
        padding: 6px;
        width: 25px;
        height: 25px;
        border-radius: 20px;
        transform: rotate(90deg);
        transition-duration: .2s;
        fill: white;
    }

    .single-row-details {
        background: #2F4455;
        min-height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;

        .delete {
            background: #9B1C4D;
            border: none;
            border-radius: 20px;
            height: 28px;
            width: 128px;
            color: white;
            font-size: 14px;
            margin-right: 15px;

            img {
                position: relative;
                top: -2px;
                right: 8px;
            }
        }

        .edit {
            background: #6793B7;
            border: none;
            border-radius: 20px;
            height: 28px;
            width: 128px;
            color: white;
            font-size: 14px;
            margin-left: 15px;
        }
    }



    
}




