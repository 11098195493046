.selected-labels, .dropdown select {
    height: 39px !important;
    padding: .375rem .75rem;
    color: #ccdbe7;
    border: 1.5px solid #6793B7;
    background: #1E2C35 !important;
    border-radius: 20px !important;
    width: 380px;
    text-align: left;

    &.empty {
        color: #5b93aa !important;
    }

    option {
        color: #ccdbe7;
    }
}

.has-error {
    & .empty {
        border-color: #cf4343 !important;
    }
}

.dropdown {
    & .options {
        width: auto;
        background: #1e2c35;
        border: 1px solid #6793b7;
        margin: -1px 15px;
        max-height: 150px;
        overflow-y: scroll;
        list-style: none;
        padding: 0;
        position: absolute;

        & li {
            padding: 2px 6px;
        }

        & .active {
            background: #6793b7;
        }
        
        & button {
            background: transparent;
            border: none;
            color: #c6dbe7;    
            width: 100%;
            text-align: left;
        }
        
        &.top {
            top: -124px;
        }
    }
}

.full-dropdown-width {
    & .options {
        width: 91%;
    }
}

.select-dropdown {
    width: 28.3px;
    height: 28.3px;
    background-color: #6793b7;
    padding: 6px 8px;
    border-radius: 20px;
    float: right;
    margin: -2px -8px;

    img {
        position: relative;
        top: -3px;
        right: 1px;
    }
} 













.dropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/dropdown.png) 99% / 20% no-repeat #1E2C35 !important;
    outline: none !important;
}

.dropdown select:focus {
    box-shadow: 0 0 10px #719ECE !important;
}
  
/* CAUTION: IE hackery ahead */
.dropdown select::-ms-expand { 
    display: none; /* remove default arrow on ie10 and ie11 */
}
  
/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
.dropdown select {
        background:none\9;
        padding: 5px\9;
    } 
}



.full-dropdown-width {
    & .dropdown select {
        background: url(../images/dropdown.png) 99% / 9% no-repeat #1E2C35 !important;
    }
}