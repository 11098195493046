.custom-radio {
    position: relative;
    cursor: pointer;
    .radio {
        width: 25px;
        height: 25px;
        border: solid 1px #fcf7b9;
        background-color: #c8e0ed;
        border-radius: 12.5px;
        display: inline-block;
    }
    .radio-checked {
        width: 15px;
        height: 15px;
        background-color: #009e87;
        border-radius: 7.5px;
        margin-left: 4px;
        margin-top: 4px;
    }
    .radio-label {
        display: inline-block;
        position: relative;
        top: -7px;
        left: 10px;
        line-height: 1.05;
        letter-spacing: 1.1px;
        color: #cddbe8;
    }
}

.regular-checkbox {
    width: 23px !important;
    height: 23px !important;
    margin-right: 10px;
    border-radius: 4px !important;
    display: inline-block;
    position: relative;
	-webkit-appearance: none;
}

.regular-checkbox:checked:after {
	content: '';
	position: absolute;
    top: 3px;
    left: 4px;
    width: 13px;
    height: 13px;
    background: #009D86;
}


@media (max-width: 570px) {
    
    .regular-checkbox:checked:after {
        left: 4px;
    }
}


.radio-width .input-field {
    height: 23px;
}

.regular-radio {
    width: 23px !important;
    height: 23px !important;
    // margin-right: 7.5px;
    display: inline-block;
    position: relative;
	-webkit-appearance: none;
	padding: 9px;
	border-radius: 50px;
}

.regular-radio:checked:after {
	content: ' ';
	position: absolute;
	border-radius: 50px;
    top: 4px;
    left: 3px;
    width: 13px;
    height: 13px;
    background: #009D86;
}

.regular-radio2:checked:after {
	content: ' ';
	position: absolute;
	border-radius: 50px;
    top: 4px;
    left: 3px;
    width: 13px;
    height: 13px;
    background: #009D86;
}


@media (max-width: 570px) {
    
    .regular-radio:checked:after {
        left: 4px;
    }
}


.datepicker-button {
    color: #ccdbe7 !important;
    border: 1.5px solid #6793B7;
    background: #1E2C35 !important;
    border-radius: 20px;
    text-align: left;

    &.empty {
        color: #678fae !important;
    }
}