.sales {
    .header {
    border-bottom: 3px solid #B2D8F7;
        .header-main {
            height: 44px;
            background-color: #3a5b77;
            display: flex;
            justify-content: center;
            align-items: center;

            .main-logo {
                width: 18px;
                height: auto;
            }

            .icon-home {
                width: 26px;
                height: 36px;
                margin-top: 17px;
                margin-left: 24px;
                cursor: pointer;
            }
        }

        .header-avatar {
            position: absolute;
            right: 10px;
            top: 9px;
            width: 28px;
            height: 28px;
            border-radius: 20px;
            cursor: pointer;
            background-color: white;
        }
    }


    .header2 {
        .btn-primary:hover {
            background-color: #b8fff6;
            border-color: #b8fff6;
        }
        button {
            width: 100%;
            height: 64px;
            background-color: #2f4455;
            border: none;
            font-size: 19px;
            border-radius: 0px;
        }
        button.active {
            background-color: #009e87 !important;
        }
        .header2-main {
            height: 64px;

            .header2-contact {
                width: 64px;
                display: inline-block;
            }
            .header2-summary {
                width: 64px;
                display: inline-block;
            }
            .header2-products {
                width: calc(100% - 128px);
                display: inline-block;
                padding-left: 6px;
            }
        }
    }
}

.dealer {
    .header {
        .header-main {
            height: 44px;
            background-color: #3a5b77;
            display: flex;
            justify-content: center;
            align-items: center;

            .main-logo {
                width: 18px;
                height: auto;
            }

            .icon-home {
                width: 26px;
                height: 36px;
                margin-top: 17px;
                margin-left: 24px;
                cursor: pointer;
            }
        }

        .header-avatar {
            position: absolute;
            right: 10px;
            top: 9px;
            width: 28px;
            height: 28px;
            border-radius: 20px;
            cursor: pointer;
            background-color: white;
        }
    }


    .header2 {
        .btn-primary:hover {
            background-color: #b8fff6;
            border-color: #b8fff6;
        }
        button {
            width: 100%;
            height: 64px;
            background-color: #2f4455;
            border: none;
            font-size: 19px;
            border-radius: 0px;
        }
        button.active {
            background-color: #009e87 !important;
        }
        .header2-main {
            height: 64px;

            .header2-contact {
                width: 64px;
                display: inline-block;
            }
            .header2-summary {
                width: 64px;
                display: inline-block;
            }
            .header2-products {
                width: calc(100% - 128px);
                display: inline-block;
                padding-left: 6px;
            }
        }
    }
}

@media (max-width: 570px) {
    .sales {
        .header {
            // .main-logo {
            //     width: 30px !important;
            //     height: 42px !important;
            //     left: calc(50% - 15px) !important;
            //     top: 14px !important;
            // }
        }

        .header2 button {
            font-size: 15px !important;
        }
    }
    .dealer {
        .header {
            // .main-logo {
            //     width: 30px !important;
            //     height: 42px !important;
            //     left: calc(50% - 15px) !important;
            //     top: 14px !important;
            // }
        }

        .header2 button {
            font-size: 15px !important;
        }
    }
}